.auth-bg {
  height: 100vh;
  background-color: #f9f9f9;
  position: relative;
}

.auth-card {
  width: 400px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
}

.auth-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

@media screen and (max-width: 500px) {
  .auth-bg {
    background-color: white;
  }
  .auth-card {
    box-shadow: none;
    border: none;
  }
}
