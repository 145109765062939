#root {
  min-height: 100vh;
}

.ant-layout {
  background: #ececec;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #0aa107;
}

.dashboard-logo {
  padding: 16px;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
  width: 100%;
  display: flex;
}

.dashboard-logo img {
  width: 100%;
}

.wifi-indicator-icon {
    position: absolute;
    bottom: 0;
    width: 18px;
    fill: #000000e0;
}

.ant-select span.ant-select-clear {
  opacity: 1;
}

.ant-picker-input span.ant-picker-clear {
  opacity: 1;
}

.ant-list-item-meta-title {
  margin-top: 0px;
}

.ant-list-items > :first-child.equipe-list {
  padding-top: 0px;
}

.ant-steps-item-process .ant-steps-item-title {
  font-weight: bold;
  margin-bottom: 16px;
}

@media screen and (max-width: 500px), (max-height: 500px) and (orientation: landscape) {
  .ant-layout {
    background: #ffffff;
  }
  .dashboard-logo {
    border-inline-end: none;
  }
  .dashboard-logo img {
    width: 50%;
  }
  main > :first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  main .ant-space.multiple-cards-space-container .ant-space-item > :first-child.ant-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .ant-layout-header {
    background-color: #0aa107 !important;
    position: relative;
  }
  .ant-layout-header:after {
    background-color: #0aa107;
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 60px;
    height: 50px;
    width: 100vw;
  }
  .ant-layout-sider-collapsed .dashboard-logo img {
    width: 100%;
  }
  .ant-layout-sider-collapsed .dashboard-logo {
    padding: 6px;
  }
  .dashboard-logo span {
    font-size: 16px;
    margin-left: auto;
    padding: 0;
  }
  main.ant-layout-content {
    margin: 1px 0 !important;
  }
  .ant-card {
    border-radius: 0;
    border: none;
  }
  .ant-menu-light.ant-menu-root.ant-menu-inline,
  .ant-menu-light.ant-menu-root.ant-menu-vertical {
    border: none;
  }
  .ant-layout-sider {
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
  }
  .button-drawer .ant-drawer-header-title {
    flex: none !important;
    min-width: calc(100vw - 48px) !important;
    margin-bottom: 16px;
  }
  .ant-drawer-title {
    padding-right: 12px;
  }
}

:where(.css-dev-only-do-not-override-pcsz4f).ant-menu .ant-menu-item .ant-menu-item-icon +span,
:where(.css-dev-only-do-not-override-pcsz4f).ant-menu .ant-menu-submenu-title .ant-menu-item-icon +span,
:where(.css-dev-only-do-not-override-pcsz4f).ant-menu .ant-menu-item .anticon +span,
:where(.css-dev-only-do-not-override-pcsz4f).ant-menu .ant-menu-submenu-title .anticon +span {
  transition: color 60ms;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.mobile-header-rounding{
  height: 20px;
  background-color: red;
  border-radius: 0 0 20px 20px;
}

.ant-drawer-header {
  flex-wrap: wrap !important;
}

.ant-drawer-body {
  position: relative;
}

.ant-modal-body {
  position: relative;
}

.ant-card-body + .ant-card-actions  {
  margin-top: -20px;
  /*margin-bottom: 16px;*/
}

.ant-card-body + .ant-card-actions .ultima-sincronizacao-barra {
  margin-bottom: 0px;
}

.ultima-sincronizacao-barra {
  margin-bottom: -16px;
  color: rgba(0, 0, 0, 0.45);
}

.ultima-sincronizacao-barra:hover {
  color: #2aad23;
}

.image-preview-inspecao {
  border: solid 1px #f0f0f0;
  border-radius: 8px;
}

.image-preview-inspecao .ant-image {
  margin-right: 16px;
  border-radius: 8px;
}

.image-preview-inspecao .ant-image-mask {
  border-radius: 8px;
}

.image-preview-inspecao .ant-image-img {
  border-radius: 8px;
}

.ant-image-preview-operations {
  background-color: rgba(0, 0, 0, 0.4);
}

.ant-image-preview-operations .ant-image-preview-operations-progress {
  top: calc(100vh - 125%);
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  user-select: none;
}

