.float-nav-menu-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
}

.float-nav-menu {
  border-radius: 6px;
  background-color: white;
  padding: 5px;
  margin-bottom: 18px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0px -1px 10px 6px rgba(0,0,0,0.05);
}

button.float-nav-menu-btn {
  width: 50px !important;
  height: 50px !important;
}

button.float-nav-menu-btn.ant-btn-primary {
  box-shadow: none;
  background-color: #cee0ca;
}

button.float-nav-menu-btn.ant-btn-primary:hover {
  background-color: #cee0ca;
}

button.float-nav-menu-btn.ant-btn-primary span.anticon {
  color: #0aa107;
}